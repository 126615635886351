import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Modal,
  Select,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat"; // Import advanced format plugin
import timezone from "dayjs/plugin/timezone"; // Import timezone plugin
import utc from "dayjs/plugin/utc"; // Import UTC plugin
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { REQUIRED_RED } from "../../App";
import {
  fetchShopClosingTime,
  mechanicDetailsList,
  proposeDateForAppointment,
} from "../../redux/appointment/action";
import SuccessMessageModal from "../Common/SuccessMessage";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
const localTimezone = dayjs.tz.guess();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};

const textAreaStyle = {
  width: "100%",
  height: "100px",
  fontSize: "16px",
  border: "1px solid #ccc",
  borderRadius: "4px",
  padding: "10px",
  fontFamily: "Roboto",
};

const RequestProposeTimeModel = ({
  open,
  handleClose,
  id,
  handleClose2,
  date,
  callNotificationAPI,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const cancelTokenSourceRef = useRef(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [assigneeList, setAssigneeList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      proposeDateTime: null,
      pickUpDateTime: null,
      assignee: "",
      note: "",
    },
  });
  console.log("🚀 ~ errors:", errors);
  useEffect(() => {
    fetchAssignees();
    getShopClosingTime(dayjs().format("YYYY-MM-DDTHH:mm"));
  }, [open]);

  const fetchAssignees = async () => {
    const data = await dispatch(mechanicDetailsList());
    if (data.length > 0) {
      setAssigneeList(data);
      setValue("assignee", data[0]?._id);
    }
  };

  const getShopClosingTime = async (time) => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
    let resp = await dispatch(
      fetchShopClosingTime({
        dropOffDateTime: dayjs(time).utc(),
        cancelToken: cancelTokenSourceRef.current.token,
      })
    );
    const localTime = dayjs(resp.shopClosingTime)
      .tz(localTimezone)
      .format("YYYY-MM-DDTHH:mm");

    setValue("pickUpDateTime", dayjs(localTime));
  };

  const validateDateTime = (proposeDateTime, estimatePickUpDateAndTime) => {
    if (proposeDateTime && estimatePickUpDateAndTime) {
      const proposeNewDate = new Date(
        proposeDateTime.$y,
        proposeDateTime.$M,
        proposeDateTime.$D,
        proposeDateTime.$H,
        proposeDateTime.$m,
        proposeDateTime.$s,
        proposeDateTime.$ms
      );
      const isoProposeDate = proposeNewDate.toISOString();

      const newDate = new Date(
        estimatePickUpDateAndTime.$y,
        estimatePickUpDateAndTime.$M,
        estimatePickUpDateAndTime.$D,
        estimatePickUpDateAndTime.$H,
        estimatePickUpDateAndTime.$m,
        estimatePickUpDateAndTime.$s,
        estimatePickUpDateAndTime.$ms
      );
      const isoPickUpDate = newDate.toISOString();

      const date1String = isoProposeDate;
      const date2String = isoPickUpDate;

      const date1 = new Date(date1String);
      const date2 = new Date(date2String);

      if (date1.getTime() === date2.getTime() || date1 > date2) {
        setError("pickUpDateTime", {
          type: "custom",
          message: "Pick up time must be after drop off.",
        });
        return true;
      } else {
        clearErrors("pickUpDateTime");
        return false;
      }
    }
  };

  const onSubmit = async (formData) => {
    const { proposeDateTime, pickUpDateTime, assignee, note } = formData;

    if (!proposeDateTime || !pickUpDateTime) {
      toast.error("Dropoff and Pickup times are required.");
      return;
    }
    if (validateDateTime(proposeDateTime, pickUpDateTime)) {
      return;
    }
    const dropOff = new Date(proposeDateTime);
    const pickUp = new Date(pickUpDateTime);

    if (dropOff >= pickUp) {
      toast.error("Pick up time must be after drop off.");
      return;
    }

    const payload = {
      proposeTime: dropOff.toISOString(),
      pickupDateTime: pickUp.toISOString(),
      assignee,
      appointmentId: id,
    };
    console.log("🚀 ~ onSubmit ~ payload:", payload);
    setShowLoader(true);

    const reschedule = await dispatch(proposeDateForAppointment(payload));
    if (reschedule) {
      setShowLoader(false);
      setOpenSuccessModal(true);
      setSuccessMessage("New proposed drop off has been sent to the customer");

      setTimeout(() => {
        handleClose();
        handleClose2();
      }, 1000);

      if (callNotificationAPI) {
        callNotificationAPI(1, false);
      }
    }
    setShowLoader(false);
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
          reset();
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              ...style,
              height: "fit-content",
              width: "800px",
              border: "none",
            }}
          >
            <AppBar
              variant="elevation"
              style={{
                height: "64px",
                boxShadow: "none",
                backgroundColor: "#002394",
                paddingRight: "0 !important",
              }}
            >
              <Toolbar variant="regular">
                <Typography variant="h6" fontWeight={500} width={"98%"}>
                  Propose New Time
                </Typography>
                <Stack direction={"row"} spacing={0}>
                  <div
                    onClick={handleClose}
                    style={{ cursor: "pointer", height: "32px" }}
                  >
                    <CloseIcon sx={{ fontSize: 32 }} />
                  </div>
                </Stack>
              </Toolbar>
            </AppBar>
            <Container
              disableGutters
              style={{ marginTop: "40px", paddingTop: "24px" }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    paddingBottom={"8px"}
                    fontWeight={500}
                    fontSize={16}
                  >
                    Drop Off Date and Time
                    <span style={{ color: REQUIRED_RED }}> *</span>
                  </Typography>
                  <Controller
                    name="proposeDateTime"
                    control={control}
                    rules={{ required: "Drop-off date and time is required" }}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          sx={{ width: "100%" }}
                          {...field}
                          value={field.value}
                          onChange={async (value) => {
                            field.onChange(value);
                            await getShopClosingTime(value);
                            validateDateTime(value, getValues().pickUpDateTime);
                          }}
                          minDate={dayjs().startOf("day")}
                          timeSteps={{ minutes: 30 }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  {errors.proposeDateTime && (
                    <Typography color="red" fontSize="14px">
                      {errors.proposeDateTime.message}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    paddingBottom={"8px"}
                    fontWeight={500}
                    fontSize={16}
                  >
                    Pick Up Date and Time
                    <span style={{ color: REQUIRED_RED }}> *</span>
                  </Typography>

                  <Controller
                    name="pickUpDateTime"
                    control={control}
                    rules={{ required: "Pick-up date and time is required" }}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          sx={{ width: "100%" }}
                          {...field}
                          value={field.value}
                          onChange={(value) => {
                            field.onChange(value);
                            validateDateTime(
                              getValues().proposeDateTime,
                              value
                            );
                          }}
                          minDate={dayjs().startOf("day")}
                          timeSteps={{ minutes: 30 }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  {errors.pickUpDateTime && (
                    <Typography color="red" fontSize="14px">
                      {errors.pickUpDateTime.message}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    className="label-500"
                    fontWeight={500}
                    fontSize={16}
                  >
                    Assign To
                    <span style={{ color: REQUIRED_RED }}> *</span>
                  </Typography>
                  <Controller
                    name="assignee"
                    control={control}
                    rules={{ required: "Assignee is required" }}
                    render={({ field }) => (
                      <Select {...field} displayEmpty fullWidth>
                        {assigneeList.map((item) => (
                          <MenuItem key={item._id} value={item._id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.assignee && (
                    <p style={{ color: "red" }}>{errors.assignee.message}</p>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    className="label-500"
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "22px",
                    }}
                  >
                    Note for Customer
                  </Typography>
                  <Controller
                    name="note"
                    control={control}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        style={textAreaStyle}
                        placeholder="Type Optional Message"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} container justifyContent="flex-end">
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleClose();
                        reset();
                      }}
                    >
                      CANCEL
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                      SAVE
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </form>
      </Modal>

      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default RequestProposeTimeModel;
